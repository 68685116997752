//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
    data() {
        return {
            mounted: false
        };
    },
    props: {
        blok: {
            type: Object,

            default: () => { }
        }
    },
    mounted() {
        let elements = [{ 
            elem: '.animate-from-left',
            percentage: 100
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        window.addEventListener('load', this.returnanimElements.bind(this, elements))
        // this.animElements.bind(this, elements)
    },
    unmounted () {
        let elements = [{ 
            elem: '.animate-from-left',
            percentage: 100
        }]
        window.addEventListener('scroll', this.returnanimElements.bind(this, elements))
        window.addEventListener('load', this.returnanimElements.bind(this, elements))
        // this.animElements.bind(this, elements)
    },
    computed: {
        ...mapState(['options', 'navMenus']),
        socialMenu() {
            return this.navMenus['social-menu']
        },
    },
    methods: {
        isElementXPercentInViewport(el, percentVisible) {
            let rect = el.getBoundingClientRect(),
                windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;

            return !(
                Math.floor(
                    100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
                ) < percentVisible ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
                percentVisible
            );
        },
        returnanimElements(elemntsAnim) {
            
            if (elemntsAnim.length > 0) {
                elemntsAnim.forEach((item) => {
                    var el = document.querySelectorAll(item.elem);
                    
                    if (el.length > 0) {
                        // window.addEventListener('load', (e) => {
                        el.forEach((element) => {
                            if (this.isElementXPercentInViewport(element, item.percentage)) {
                                element.classList.add("in_view");
                            } else {
                                element.classList.remove("in_view");
                            }
                        });
                    }
                });
            }
        },
    },
};
